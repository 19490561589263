import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Locale } from 'date-fns';
import { de, enGB, nb, sv } from 'date-fns/locale';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, filter, startWith } from 'rxjs/operators';

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en-GB';
import localeNoExtra from '@angular/common/locales/extra/nb';
import localeSvExtra from '@angular/common/locales/extra/sv';
import localeNo from '@angular/common/locales/nb';
import localeSv from '@angular/common/locales/sv';
import { getLocalStorage } from '../utils/getLocalStorage';

/**
 * Wrapper for translate service holding current locale state
 */
@Injectable({ providedIn: 'root' })
export class LanguageService implements OnDestroy {
  subscriptions: Subscription[] = [];
  onLangChange = new ReplaySubject<LangChangeEvent>(1);

  private _locale!: Locale;

  get dateLocale(): Locale {
    if (!this._locale) this.setDefaults();
    return this._locale;
  }

  get supportedLanguages() {
    return ['nb', 'se', 'en', 'de'];
  }

  /**
   * These should not be translated!
   */
  get supportedLanguageOptions() {
    return [
      { language: 'Norsk', code: 'nb' },
      { language: 'English', code: 'en' },
      { language: 'Svenska', code: 'se' },
      { language: 'Deutch', code: 'de' },
    ];
  }

  get current(): string {
    return this.translate.currentLang;
  }

  get currentOption(): { language: string; code: string } {
    const currentCode = this.current;

    return (
      this.supportedLanguageOptions.find((languageOption) => languageOption.code === currentCode) ||
      this.supportedLanguageOptions[0]
    );
  }

  get translations() {
    return this.translate.getTranslation(this.current);
  }

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
  ) {
    // this.onLangChange.next({ lang: this.translate.currentLang, translations: this.translate.translations });
    this.subscriptions.push(
      this.translate.onLangChange
        .pipe(
          startWith({ lang: this.translate.currentLang, translations: this.translate.translations }),
          filter((lng) => !!lng.lang),
        )
        .subscribe((lng) => {
          this.change(lng.lang);
          this.onLangChange.next(lng);
        }),
    );
  }

  getLocale(): string {
    return this.dateLocale?.code || '';
  }

  /**
   * Set translation defaults
   * This will detect browser language and set this if nothing is previously stored
   * in localStorage. The configuration will be set back to localStorage once detected.
   */
  setDefaults() {
    this.translate.addLangs(this.supportedLanguages);
    // this.translate.setDefaultLang('no');
    const browserLang: string = navigator.language;
    const newLang = getLocalStorage().getItem('lang') || browserLang;
    this.change(newLang ? newLang : this.translate.getDefaultLang());
  }

  /**
   *
   */
  change(lang: string) {
    if (!lang) return;
    lang = lang.toLowerCase();
    if (lang.match(/no|nb|nn|no-nb/i)) {
      lang = 'no';
      this._locale = nb;
      registerLocaleData(localeNo, 'nb', localeNoExtra);
    } else if (lang.match(/se|sv|sv_se/i)) {
      lang = 'se';
      this._locale = sv;
      registerLocaleData(localeSv, 'sv', localeSvExtra);
    } else if (lang.match(/de/i)) {
      lang = 'de';
      this._locale = de;
      registerLocaleData(localeDe, 'de', localeDeExtra);
    } else {
      // The default fallback
      lang = 'en';
      this._locale = enGB;
      registerLocaleData(localeEn, 'en-GB', localeEnExtra);
    }
    getLocalStorage().setItem('lang', lang);
    if (lang !== this.translate.currentLang) {
      return this.translate.use(lang);
    }
    this.getLocale();
    return of(lang);
  }

  loadTranslations(type: string) {
    return this.http
      .get<{ [key: string]: string }>(`/lang/${type}/${this.current}.json`, { headers: { NoLoad: 'true' } })
      .pipe(catchError((err) => of({})));
  }

  ngOnDestroy() {
    this.subscriptions.filter((s) => !!s && !s.closed).forEach((s) => s.unsubscribe());
  }
}
